<template>
    <v-card elevation="2" tile width="100%" class="pa-0 pa-4 position-relative">
        <v-row class="ma-0 pa-0">
            <v-col cols="4" class="pa-0"> 
                <div class="skeleton-list-item w-full" v-if="msgLoading">
                    <v-skeleton-loader  :loading="true" type="image"></v-skeleton-loader>
                </div>
                <vue-perfect-scrollbar style="height: calc(100vh -200px)" v-if="!msgLoading">
                    <div class="pr-2">
                        <div v-html="model.ON_AIR_LONG_MESSAGE"></div>
                    </div>
                </vue-perfect-scrollbar>
            </v-col>
            <v-col cols="8" class="pa-0">
                <div class="skeleton-list-item w-full" v-if="msgLoading">
                    <v-skeleton-loader  :loading="true" type="image"></v-skeleton-loader>
                </div>
                <vue-perfect-scrollbar style="height: calc(100vh - 200px)"  v-if="!msgLoading">
                    <div class="pl-2">
                        <div v-html="model.ON_AIR_SHORT_MESSAGE"></div> 
                    </div>
                </vue-perfect-scrollbar>
            </v-col>
        </v-row>
    </v-card>
</template>

<script>
import { mapActions } from 'vuex';

export default {
    data: (vm) => ({
        model: {
            ON_AIR_SHORT_MESSAGE: null,
            ON_AIR_LONG_MESSAGE: null,
        },
        msgLoading: false
    }),
    created() {
        this.getInfoData();
    },
    mounted() {
        this.wsMsgUpdate();
    },
    methods: {
        ...mapActions(["getOptionsData"]),

        getInfoData() {
            this.msgLoading = true;
            const reqData = {
                name: 'search',
                value: {
                    option_name: ['ON_AIR_SHORT_MESSAGE', 'ON_AIR_LONG_MESSAGE'],
                }
            }
            this.getOptionsData(reqData).then((response) => {
                this.model = {...this.model, ...response.data.data};
                this.msgLoading = false;
            }).catch((err) => this.msgLoading = false);
        },
        wsMsgUpdate() {
            Echo.channel('ON_AIR_SHORT_MESSAGE').listen('.saved',(msg) => {
                this.model.ON_AIR_SHORT_MESSAGE = msg.data;
            });
            Echo.channel('ON_AIR_LONG_MESSAGE').listen('.saved',(msg) => {
                this.model.ON_AIR_LONG_MESSAGE = msg.data;
            });
        },
    },
    beforeDestroy() {
        Echo.leaveChannel('ON_AIR_SHORT_MESSAGE');
        Echo.leaveChannel('ON_AIR_LONG_MESSAGE');
    }
}
</script>
